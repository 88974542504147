import React, { useState } from 'react'
import { Button, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import { login } from '../../services/authentication'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    maxWidth: '90vw',
    padding: theme.spacing(2),
    marginTop: -150,
    marginLeft: -200,
    borderRadius: 4,
    boxShadow: '0 0 4px 0px rgba(0, 0, 0, 0.2)',

    '& h2': {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },

    '& .MuiButtonBase-root': {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
    },

    '& .MuiLinearProgress-root': {
      height: 4,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 2,
      marginBottom: -6,
    },
  },
}))

export default function Login(props) {
  const { setAuthenticated } = props
  const [error, setError] = useState('')
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          } else if (!values.password) {
            errors.password = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          login(values)
            .then((result) => {
              setSubmitting(false)
              if (result) {
                setAuthenticated(true)
              } else {
                setError('Invalid email or password')
              }
            })
            .catch((error) => {
              setSubmitting(false)
              setError(error)
            })
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form className={classes.form} acceptCharset="UTF-8">
            <h2>Heylink Login</h2>
            <Field
              component={TextField}
              name="email"
              type="email"
              label="Email"
              variant="outlined"
            />
            <Field
              component={TextField}
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              variant="outlined"
            />
            {isSubmitting && <LinearProgress />}
            {error && <p>{error}</p>}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
