import React, { Fragment } from 'react'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { navigateTo } from '../../services/utils'

export const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  sidebar: theme.mixins.toolbar,
  sectionHeader: {
    padding: theme.spacing(2),
    fontSize: '1rem',
    fontWeight: 500,

    '&:not(:first-of-type)': {
      paddingTop: theme.spacing(4),
    },
  },
}))

const paths = [
  {
    title: 'Resources',
    path: '/admin_users',
    text: 'Admin Users',
  },
  {
    path: '/contracts',
    text: 'Campaigns',
  },
  {
    path: '/conversion_rules',
    text: 'Conversion Rules',
  },
  {
    path: '/callbacks',
    text: 'Callbacks',
  },
  {
    title: 'Connect',
    path: '/publishers',
    text: 'Publishers',
  },
  {
    path: '/publisher_users',
    text: 'Publisher Users',
  },
  {
    path: '/advertisers',
    text: 'Advertisers',
  },
  {
    path: '/advertiser_users',
    text: 'Advertiser Users',
  },
  {
    path: '/articles',
    text: 'Help Articles',
  },
  {
    title: 'Settings',
    path: '/settings/direct-css',
    text: 'Custom Direct CSS',
  },
  {
    path: '/settings/login-css',
    text: 'Custom Login CSS',
  },
]

export default function Sidebar(props) {
  const classes = useStyles()

  const isCurrentPage = (path) => window.location.pathname.startsWith(path)

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      anchor="left"
    >
      <div className={classes.sidebar} />
      <Divider />
      <List>
        {paths.map((item) => (
          <Fragment key={item.path}>
            {item.title && (
              <div className={classes.sectionHeader}>{item.title}</div>
            )}
            <ListItem
              button
              selected={isCurrentPage(item.path)}
              onClick={(event) => {
                navigateTo(event, item.path)
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          </Fragment>
        ))}
      </List>
    </Drawer>
  )
}
