import * as moment from 'moment'
import { compact } from 'lodash'
import { navigate } from 'gatsby'

export const navigateTo = (event, target) => {
  if (!target && typeof event === 'string') {
    navigate(event)
  } else if (event.ctrlKey || event.metaKey || event.which === 2) {
    window.open(target, '_blank')
  } else {
    navigate(target)
  }
}

export const sortBy = (field) => (a, b) => {
  if (a[field] < b[field]) {
    return -1
  } else if (a[field] > b[field]) {
    return 1
  } else {
    return 0
  }
}

export const reverseSortBy = (field) => (a, b) => {
  if (a[field] < b[field]) {
    return 1
  } else if (a[field] > b[field]) {
    return -1
  } else {
    return 0
  }
}

export const formatDate = (t) => moment(t).format('DD/MM/YYYY')
export const formatDatetime = (t) => moment(t).format('DD MMM YYYY, hh:mm')
export const formatDatetimeSec = (t) =>
  moment(t).format('DD MMM YYYY, hh:mm:ss')

export const isEmail = (value) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

export const pluralized = (str) => {
  if (str.endsWith('s')) {
    return `${str}es`
  } else if (str.endsWith('y')) {
    return str.replace(/y$/, 'ies')
  } else {
    return `${str}s`
  }
}

export const pluralize = (num, str, s = 's') => {
  if (num === 1) {
    return `${num} ${str}`
  } else {
    return `${num} ${pluralized(str)}`
  }
}

export const contractTerminationReason = (record) => {
  switch (record.terminationReason) {
    case 'breach':
      return 'Breach of contract'
    case 'mutual':
      return 'Mutual agreement'
    default:
      return 'Unset'
  }
}

export const contractStatus = (record, full = false) => {
  if (record.terminatedAt) {
    if (full) {
      return `Terminated on ${formatDatetime(
        record.terminatedAt
      )}. Reason: ${contractTerminationReason(record)}. Description: ${
        record.terminationDescription
      }`
    } else {
      return 'Terminated'
    }
  }
  if (record.pausedAt) {
    if (full) {
      return `Paused on ${formatDatetime(record.pausedAt)}`
    } else {
      return 'Paused'
    }
  }
  switch (record.status) {
    case 'pending':
      return 'Pending'
    case 'sent':
      return 'Contract sent'
    case 'signed':
      return 'Contract signed'
    case 'installed':
      return 'Tracking installed'
    case 'running':
      return 'Running'
    default:
      return record.status
  }
}

export const contractTuneStatus = (record) => {
  switch (record.tuneStatus) {
    case 'initial':
      return 'Not created yet'
    case 'failed':
      return 'Failed to create'
    case 'pending':
      return 'Pending'
    case 'active':
      return 'Active'
    case 'paused':
      return 'Paused'
    case 'deleted':
      return 'Deleted'
    default:
      return record.status
  }
}

export const contractPartnership = (value) => {
  switch (value) {
    case 'simple':
      return 'Ønskeskyen ApS'
    case 'extended':
      return 'Ønskeskyen ApS and premium publishers'
    default:
      return 'None'
  }
}

export const contractCms = (cms, custom) => {
  if (cms?.toLowerCase() === 'other') {
    return custom
  } else {
    return cms
  }
}

export const articleType = (record) => {
  switch (record.category) {
    case 'cms':
      return (
        compact([
          record.slug.toLowerCase() === 'global' && 'General',
          record.isMajor && 'Major',
          record.isMinor && 'Minor',
        ]).join(', ') + ' CMS'
      )

    case 'help':
      return 'Help article'

    default:
      return ''
  }
}

export const articlePath = (record) => {
  switch (record.category) {
    case 'cms':
      return `/help/cms/${encodeURIComponent(record.slug)}`
    case 'help':
      return `/help/${encodeURIComponent(record.slug)}`
    default:
      return ''
  }
}

export const toQuery = (obj) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&')
