import React, { useState, useEffect } from 'react'
import { CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Header from './Header'
import Sidebar from './Sidebar'
import Login from '../auth/Login'
import { checkLogin } from '../../services/authentication'
import { drawerWidth } from './Sidebar'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: drawerWidth,
  },
}))

export default function Layout(props) {
  const [isAuthenticated, setAuthenticated] = useState(null)
  const { children } = props
  const classes = useStyles()

  useEffect(() => {
    if (!isAuthenticated) {
      setAuthenticated(checkLogin())
    }
  }, [isAuthenticated, setAuthenticated])

  if (isAuthenticated === null) {
    return null
  } else if (isAuthenticated) {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <main className={classes.content}>{children}</main>
        <Sidebar />
      </div>
    )
  } else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <Login setAuthenticated={setAuthenticated} />
        </main>
      </div>
    )
  }
}
